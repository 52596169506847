import React, { FC, ReactNode, useContext, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { AuthContext } from 'src/contexts/AuthContext'

interface AuthenticatedProps {
  children?: ReactNode;
}

const Authenticated: FC<AuthenticatedProps> = ({ children }) => {
  const { setUser } = useContext(AuthContext)
  const user: any = JSON.parse(localStorage.getItem('__userinfo') as string)
  const company: any = JSON.parse(localStorage.getItem('__company') as string)

  useEffect(() => {
    setUser({user, company})
  }, [])

  if (!user) {
    return <Redirect to="/login" />
  }

  return <>{children}</>
}

export default Authenticated
