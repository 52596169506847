import React, { Fragment, useContext, useEffect, useState } from "react";
import { Menu } from "antd";
import {
  UserOutlined,
  GlobalOutlined,
  ReadOutlined,
  ApartmentOutlined,
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
  BlockOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { AuthContext } from "src/contexts/AuthContext";
import "./index.less";
import checkAuth from "src/utils/checkAuth";

const { SubMenu } = Menu;
const userinfo: any = JSON.parse(localStorage.getItem("__userinfo") as string);

const menuMap: { [key: string]: string } = {
  "/user/all": "sub0",
  "/user/admin": "sub0",
  "/user/soldier": "sub0",
  "/user/company": "sub0",
  "/user/publisher": "sub0",
  "/user/miniapp-admin": "sub0",

  "/space/manage": "sub1",
  "/space/channel": "sub1",
  "/space/setting": "sub1",

  "/content/all": "sub2",
  "/content/infomation": "sub2",
  "/content/graphics": "sub2",
  "/content/notice": "sub2",
  "/content/activity": "sub2",
  "/content/project": "sub2",
  "/content/infomation/create": "sub2",
  "/content/graphics/create": "sub2",
  "/content/notice/create": "sub2",
  "/content/activity/create": "sub2",
  "/content/project/create": "sub2",

  "/company/info": "sub3",
  "/company/license": "sub3",
  "/company/publisher": "sub3",

  "/job/publish": "sub4",
  "/job/published": "sub4",
  "/job/progress": "sub4",
  "/job/closed": "sub4",

  "/resume/receive": "sub5",
  "/resume/reply": "sub5",
  "/resume/hire": "sub5",
  "/resume/no-hire": "sub5",

  "/fair/publish": "sub6",
  "/fair/published": "sub6",
  "/fair/list": "sub6",
  "/fair/attend": "sub6",
};

const rootSubmenuKeys = [
  "sub0",
  "sub1",
  "sub2",
  "sub3",
  "sub4",
  "sub5",
  "sub6",
];

const Sidebar = () => {
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const [openKeys, setOpenKeys] = useState([""]);
  const [selectedKeys, setSelectedKeys] = useState([""]);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const handleClick = (menu: any) => {
    history.push(menu.key);
  };

  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    console.log("history => ", history);
    console.log("用户信息 => ", user);
    if (!user) {
      setIsAdmin(userinfo.is_admin);
    } else {
      setIsAdmin(user.is_admin);
    }
    menuMap[history.location.pathname]
      ? setOpenKeys([menuMap[history.location.pathname]])
      : setOpenKeys([""]);
    setSelectedKeys([history.location.pathname]);
  }, [history.location.pathname]);

  return (
    <>
      <div className="sidebar-container">
        <Menu
          onClick={handleClick}
          style={{ width: 260 }}
          onOpenChange={onOpenChange}
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          mode="inline"
        >
          {checkAuth("user") && (
            <SubMenu key="sub0" icon={<UserOutlined />} title="用户设置">
              <Menu.Item key="/user/all">全部用户</Menu.Item>
              {isAdmin && (
                <Fragment>
                  <Menu.Item key="/user/admin">系统管理员</Menu.Item>
                  <Menu.Item key="/user/miniapp-admin">小程序管理员</Menu.Item>
                </Fragment>
              )}

              {/* <Menu.Item key="/user/soldier">退役士兵</Menu.Item>
            <Menu.Item key="/user/company">企业招聘负责人</Menu.Item>
            <Menu.Item key="/user/publisher">信息发布经办人</Menu.Item> */}
            </SubMenu>
          )}
          {/* {isAdmin && <Menu.Item key="/company/manage" icon={<ApartmentOutlined />}>企业机构管理</Menu.Item>} */}
          {checkAuth("miniapp/view") && (
            <Menu.Item key="/app/manage" icon={<AppstoreOutlined />}>
              小程序管理
            </Menu.Item>
          )}
          {checkAuth("space") && (
            <SubMenu key="sub1" icon={<GlobalOutlined />} title="空间管理">
              <Menu.Item key="/space/manage">空间管理</Menu.Item>
              <Menu.Item key="/space/channel">频道管理</Menu.Item>
            </SubMenu>
          )}
          {checkAuth("space/content") && (
            <SubMenu key="sub2" icon={<ReadOutlined />} title="内容管理">
              <Menu.Item key="/content/all">全部内容</Menu.Item>
              <Menu.Item key="/content/infomation">全资讯</Menu.Item>
              <Menu.Item key="/content/graphics">图文</Menu.Item>
              <Menu.Item key="/content/notice">通知</Menu.Item>
              <Menu.Item key="/content/activity">活动行</Menu.Item>
              <Menu.Item key="/content/project">项目</Menu.Item>
            </SubMenu>
          )}
          {/* {checkAuth('space/content') && <SubMenu key="sub3" icon={<MailOutlined />} title="基本信息">
            <Menu.Item key="/company/info">企业基本信息</Menu.Item>
            <Menu.Item key="/company/license">营业执照信息</Menu.Item>
            <Menu.Item key="/company/publisher">信息发布经办人</Menu.Item>
          </SubMenu>} */}
          {/* {!isAdmin && <SubMenu key="sub4" icon={<AppstoreOutlined />} title="职位管理">
            <Menu.Item key="/job/publish">发布新职位</Menu.Item>
            <Menu.Item key="/job/published">已发布职位</Menu.Item>
            <Menu.Item key="/job/progress">进行中的职位</Menu.Item>
            <Menu.Item key="/job/closed">已关闭的职位</Menu.Item>
          </SubMenu>} */}
          {/* {!isAdmin && <SubMenu key="sub5" icon={<SnippetsOutlined />} title="简历管理">
            <Menu.Item key="/resume/receive">收到的简历</Menu.Item>
            <Menu.Item key="/resume/reply">回复的简历</Menu.Item>
            <Menu.Item key="/resume/hire">录用的简历</Menu.Item>
            <Menu.Item key="/resume/no-hire">未录用的简历</Menu.Item>
          </SubMenu>} */}
          {/* <SubMenu key="sub6" icon={<BlockOutlined />} title="专场招聘会">
            {isAdmin && <Menu.Item key="/fair/publish">发布专场招聘会</Menu.Item>}
            {isAdmin && <Menu.Item key="/fair/published">已发布的专场招聘会</Menu.Item>}
            {!isAdmin && <Menu.Item key="/fair/list">专场招聘会目录</Menu.Item>}
            {!isAdmin && <Menu.Item key="/fair/attend">已参加的专场招聘会</Menu.Item>}
          </SubMenu> */}
          {isAdmin && (
            <Menu.Item key="/system/manage" icon={<SettingOutlined />}>
              系统管理
            </Menu.Item>
          )}
        </Menu>
      </div>
    </>
  );
};

export default Sidebar;
