import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import reportWebVitals from './reportWebVitals'
import io from 'src/utils/io'

// 动态主题色修改
ConfigProvider.config({
  theme: {
    primaryColor: '#5D93FF',
  }
})

const localTheme = localStorage.getItem('yingTheme')
if (localTheme) {
  const localThemeObj = JSON.parse(localTheme)
  for(const key in localThemeObj) {
    document.documentElement.style.setProperty(key, localThemeObj[key])
  }
} else {
  io.get('/api/v2/syscfg/web').then((res: any) => {
    if(res.theme) {
      localStorage.setItem('yingTheme', JSON.stringify(res.theme))
      const theme = res.theme
      for(const key in theme) {
        document.documentElement.style.setProperty(key, theme[key])
      }
    }
  })
}

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={zhCN}>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
