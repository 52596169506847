import React, { FC, ReactNode } from 'react'

interface GuestProps {
  children?: ReactNode;
}

const Guest: FC<GuestProps> = ({ children }) => {
  return <>{children}</>
}

export default Guest
