import axios from 'axios'
import queryString from 'query-string'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  timeout: 600000
})

const getTokenFromUrl = () => {
  if (typeof window !== 'undefined') {
    const queryParams: any = queryString.parse(window.location.search)
    if (queryParams.token) {
      localStorage.setItem('serviceManAccessToken', queryParams.token)
    }
    return queryParams.token
  }
  return ''
}

const getTokenFromStorage = () => {
  return window.localStorage.getItem('serviceManAccessToken')
}

const getToken = () => {
  let token = getTokenFromUrl()
  if (!token) {
    token = getTokenFromStorage()
  }
  return token
}

axiosInstance.interceptors.request.use(function (config) {
  const token: string = getToken()
  if(config.headers!.token) { // eslint-disable-line
    config.headers!.Authorization = `Bearer ${config.headers!.token}` // eslint-disable-line
  }else if (token) {
    config.headers!.Authorization = `Bearer ${token}` // eslint-disable-line
  }else{
    config.headers!.Authorization = '' // eslint-disable-line
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

axiosInstance.interceptors.response.use(function (response) {
  const data = response.data
  if(response.status === 200 || response.status === 201) {
    return data
  }
  return response
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

export default axiosInstance
