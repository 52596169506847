const checkAuth = (path: string) => {
  let info = JSON.parse(localStorage.getItem('__userinfo') as string)
  if (!info) return false
  let auth = info['permission']
  if (!auth) return false
  let pathArr = path.split('/')
  if (auth.includes('*')) {
    return true;
  }
  if (auth.includes(path)) {
    return true;
  }
  let res = false;
  for (let n = 0; n < auth.length; n++) {
    let val = auth[n]
    let valArr = val.split('.')
    let len = valArr.length
    for (let i = 0; i < len; i++) {
      if (valArr[i] === '*') {
        res = true
      } else if (valArr[i] === pathArr[i]) {
        res = true
      } else if (valArr[i] !== pathArr[i]) {
        res = false
        break;
      }
    }
    if (res) {
      break;
    }
  }
  return res
}

export default checkAuth;
