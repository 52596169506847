import React, { createContext, FC, ReactNode, useCallback, useEffect, useState} from 'react'
import { useHistory } from 'react-router'
import io from 'src/utils/io'

interface AuthContextProps {
  user: any;
  enterprise: any;
  guestToken: string,
  setUser: Function; // eslint-disable-line
  logout: Function; // eslint-disable-line
}

export const AuthContext = createContext<AuthContextProps>({
  user: {},
  enterprise: {},
  guestToken: '',
  setUser: () => {},
  logout: () => {}
})

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [userInfo, setUserInfo] = useState<any>()
  const [companyInfo, setCompanyInfo] = useState<any>()
  const [guestToken, setGuestToken] = useState<string>('')
  const history = useHistory()

  useEffect(() => {
    const userinfo: any = JSON.parse(localStorage.getItem('__userinfo') as string)
    const company: any = JSON.parse(localStorage.getItem('__company') as string)
    if (userinfo || company) {
      setUserInfo(userinfo)
      setCompanyInfo(company)
    }
    io.get('/api/v2/guest-token').then((res: any) => {
      setGuestToken(res.token)
    })
  }, [])

  const setUser = useCallback((data: any) => {
    if(data.user) {
      localStorage.setItem('serviceManAccessToken', data.user.token)
      localStorage.setItem('__userinfo', JSON.stringify(data.user))
      setUserInfo(data.user)
    }
    if(data.company) {
      localStorage.setItem('__company', JSON.stringify(data.company))
      setCompanyInfo(data.company)
    }
  }, [history])

  const logout = useCallback(() => {
    setUserInfo(undefined)
    localStorage.removeItem('serviceManAccessToken')
    localStorage.removeItem('__userinfo')
    localStorage.removeItem('__company')
    window.location.href = '/login'
  }, [history])

  return (
    <AuthContext.Provider
      value={{
        user: userInfo,
        enterprise: companyInfo,
        guestToken,
        setUser,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
