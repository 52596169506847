import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import routes, { renderRoutes } from './router'
import { AuthProvider } from './contexts/AuthContext'
import './App.less'

function App () {
  return (
    <BrowserRouter>
      <AuthProvider>
        {renderRoutes(routes)}
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App
