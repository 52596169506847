import React from 'react'
import Logo from 'src/assets/images/logo.png'
import './index.less'

const Header = () => {
  return (
    <div className="login-header w-full">
      <div className="bd flex items-center">
        <img className="brand-img" src={Logo} alt="赢之营创新研究院" />
        <div className="brand-info">
          <p className="tip-1">赢之营创新研究院</p>
          <p className="devide-line"></p>
          <p className="tip-2">后台管理系统</p>
        </div>
      </div>
    </div>
  )
}

export default Header
