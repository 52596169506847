import React, { FC, ReactNode } from 'react'
import Header from './Header'
import Footer from './Footer'
import Sidebar from './Sidebar'
import './layout.less'

interface BaseLayoutProps {
  children?: ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  return (
    <>
      <div className="main-body w-full min-h-full relative" style={{paddingBottom: '118px'}}>
        <Header />
        <div className="sidebar-con overflow-y-auto">
          <Sidebar />
        </div>
        <div className="main-content">{children}</div>
        <Footer />
      </div>
    </>
  )
}

export default BaseLayout
