import React, { Suspense, Fragment, lazy } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import Authenticated from 'src/components/Authenticated'
import Guest from 'src/components/Guest'
import LoginLayout from 'src/components/Layout/LoginLayout'
import BaseLayout from 'src/components/Layout/BaseLayout'

type Routes = {
  exact?: boolean;
  title?: string;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
  sidebar?: boolean;
  permission?: string;
}[]

export const renderRoutes = (routes: Routes = []): JSX.Element => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment
          const Layout = route.layout || Fragment
          const Component = route.component

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          )
        })}
      </Switch>
    </Suspense>
  )
}

const routes: Routes = [
  {
    path: '/user',
    title: '用户设置',
    guard: Authenticated,
    layout: BaseLayout,
    sidebar: true,
    permission: 'user-setting',
    routes: [
      {
        exact: true,
        path: '/user/all',
        title: '全部用户',
        component: lazy(() => import('src/views/User/All'))
      },
      {
        exact: true,
        path: '/user/admin',
        title: '系统管理员',
        component: lazy(() => import('src/views/User/Admin'))
      },
      {
        exact: true,
        path: '/user/miniapp-admin',
        title: '小程序管理员',
        component: lazy(()=> import('src/views/User/MiniAppAdmin'))
      },
      {
        exact: true,
        path: '/user/soldier',
        title: '退役士兵',
        component: lazy(() => import('src/views/User/Soldier'))
      },
      {
        exact: true,
        path: '/user/company',
        title: '企业招聘负责人',
        component: lazy(() => import('src/views/User/Company'))
      },
      {
        exact: true,
        path: '/user/publisher',
        title: '信息发布经办人',
        component: lazy(() => import('src/views/User/Publisher'))
      },
      {
        exact: true,
        path: '/user/editcode',
        title: '修改密码',
        component: lazy(() => import('src/views/User/EditCode'))
      }
    ]
  },
  {
    path: '/space',
    title: '空间管理',
    guard: Authenticated,
    layout: BaseLayout,
    sidebar: true,
    permission: 'space-setting',
    routes: [
      {
        exact: true,
        path: '/space/manage',
        title: '空间管理',
        component: lazy(() => import('src/views/Space/Manage'))
      },
      {
        exact: true,
        path: '/space/setting',
        title: '空间设置',
        component: lazy(() => import('src/views/Space/Setting'))
      },
      {
        exact: true,
        path: '/space/channel',
        title: '频道管理',
        component: lazy(() => import('src/views/Space/Channel'))
      },
      {
        exact: true,
        path: '/space/channel/connect',
        title: '项目收录设置',
        component: lazy(() => import('src/views/Space/Channel/Connect/Project'))
      }
    ]
  },

  {
    path: '/content',
    title: '内容管理',
    guard: Authenticated,
    layout: BaseLayout,
    sidebar: true,
    permission: 'content-setting',
    routes: [
      {
        exact: true,
        path: '/content/all',
        title: '全部内容',
        component: lazy(() => import('src/views/Content/All'))
      },
      {
        exact: true,
        path: '/content/infomation',
        title: '全资讯',
        component: lazy(() => import('src/views/Content/Infomation'))
      },
      {
        exact: true,
        path: '/content/infomation/create',
        title: '全资讯',
        component: lazy(() => import('src/views/Content/Infomation/Create'))
      },
      {
        exact: true,
        path: '/content/graphics',
        title: '图文',
        component: lazy(() => import('src/views/Content/Graphics'))
      },
      {
        exact: true,
        path: '/content/graphics/create',
        title: '图文',
        component: lazy(() => import('src/views/Content/Graphics/Create'))
      },
      {
        exact: true,
        path: '/content/notice',
        title: '通知',
        component: lazy(() => import('src/views/Content/Notice'))
      },
      {
        exact: true,
        path: '/content/notice/create',
        title: '通知',
        component: lazy(() => import('src/views/Content/Notice/Create'))
      },
      {
        exact: true,
        path: '/content/activity',
        title: '活动行',
        component: lazy(() => import('src/views/Content/Activity'))
      },
      {
        exact: true,
        path: '/content/activity/create',
        title: '活动行',
        component: lazy(() => import('src/views/Content/Activity/Create'))
      },
      {
        exact: true,
        path: '/content/activity/content/create',
        title: '活动行',
        component: lazy(() => import('src/views/Content/Activity/CreateContent'))
      },
      {
        exact: true,
        path: '/content/project',
        title: '项目',
        component: lazy(() => import('src/views/Content/Project'))
      },
      {
        exact: true,
        path: '/content/project/create',
        title: '项目',
        component: lazy(() => import('src/views/Content/Project/Create'))
      }
    ]
  },
  {
    path: '/company',
    title: '基本信息',
    guard: Authenticated,
    layout: BaseLayout,
    sidebar: true,
    permission: 'company-info',
    routes: [
      {
        exact: true,
        path: '/company/manage',
        title: '企业机构管理',
        component: lazy(() => import('src/views/Company/Manage'))
      },
      {
        exact: true,
        path: '/company/info',
        title: '企业基本信息',
        component: lazy(() => import('src/views/Company/Info'))
      },
      {
        exact: true,
        path: '/company/license',
        title: '营业执照信息',
        component: lazy(() => import('src/views/Company/License'))
      },
      {
        exact: true,
        path: '/company/publisher',
        title: '信息发布经办人',
        component: lazy(() => import('src/views/Company/Publisher'))
      },
      {
        exact: true,
        path: '/company/publisher/create',
        title: '添加信息发布经办人',
        component: lazy(() => import('src/views/Company/Publisher/Create'))
      }
    ]
  },
  {
    path: '/fair',
    title: '专场招聘会',
    guard: Authenticated,
    layout: BaseLayout,
    sidebar: true,
    routes: [
      {
        exact: true,
        path: '/fair/publish',
        permission: 'publish-fair',
        title: '发布专场招聘会',
        component: lazy(() => import('src/views/Fair/Publish'))
      },
      {
        exact: true,
        path: '/fair/published',
        title: '已发布的专场招聘会',
        permission: 'published-fair',
        component: lazy(() => import('src/views/Fair/Published'))
      },
      {
        exact: true,
        path: '/fair/list',
        title: '专场招聘会目录',
        permission: 'fair-list',
        component: lazy(() => import('src/views/Fair/List'))
      },
      {
        exact: true,
        path: '/fair/attend',
        title: '已参加的专场招聘会',
        permission: 'fair-attend',
        component: lazy(() => import('src/views/Fair/Attend'))
      }
    ]
  },
  {
    path: '/job',
    title: '职位管理',
    guard: Authenticated,
    layout: BaseLayout,
    sidebar: true,
    permission: 'job-manage',
    routes: [
      {
        exact: true,
        path: '/job/published',
        title: '已发布职位',
        permission: 'published-job',
        component: lazy(() => import('src/views/Job/Published'))
      },
      {
        exact: true,
        path: '/job/progress',
        title: '进行中的职位',
        permission: 'in-progress-job',
        component: lazy(() => import('src/views/Job/Progress'))
      },
      {
        exact: true,
        path: '/job/closed',
        title: '已关闭的职位',
        component: lazy(() => import('src/views/Job/Closed'))
      },
      {
        exact: true,
        path: '/job/publish',
        title: '发布新职位',
        component: lazy(() => import('src/views/Job/Publish'))
      }
    ]
  },
  {
    path: '/resume',
    title: '简历管理',
    guard: Authenticated,
    layout: BaseLayout,
    sidebar: true,
    permission: 'resume-manage',
    routes: [
      {
        exact: true,
        path: '/resume/receive',
        title: '收到的简历',
        permission: 'receive-resume',
        component: lazy(() => import('src/views/Resume/Receive'))
      },
      {
        exact: true,
        path: '/resume/reply',
        title: '回复的简历',
        component: lazy(() => import('src/views/Resume/Reply'))
      },
      {
        exact: true,
        path: '/resume/hire',
        title: '录用的简历',
        component: lazy(() => import('src/views/Resume/Hire'))
      },
      {
        exact: true,
        path: '/resume/no-hire',
        title: '未录用的简历',
        permission: 'no-hire-resume',
        component: lazy(() => import('src/views/Resume/NoHire'))
      }
    ]
  },
  {
    path: '/app',
    title: '小程序管理',
    guard: Authenticated,
    layout: BaseLayout,
    sidebar: true,
    permission: 'app-manage',
    routes: [
      {
        exact: true,
        path: '/app/manage',
        title: '小程序管理',
        component: lazy(() => import('src/views/App/Manage'))
      }
    ]
  },
  {
    path: '/system',
    title: '系统管理',
    guard: Authenticated,
    layout: BaseLayout,
    sidebar: true,
    permission: 'system-manage',
    routes: [
      {
        exact: true,
        path: '/system/manage',
        title: '系统管理',
        component: lazy(() => import('src/views/System/Manage'))
      }
    ]
  },
  // {
  //   path: '/talent-pool',
  //   title: '人才库',
  //   guard: Authenticated,
  //   layout: BaseLayout,
  //   sidebar: true,
  //   permission: 'talent-pool',
  //   routes: [
  //     {
  //       exact: true,
  //       path: '/talent-pool/soldier',
  //       title: '退役士兵',
  //       component: lazy(() => import('src/views/TalentPool'))
  //     }
  //   ]
  // },
  {
    exact: true,
    path: '/login',
    title: '登录',
    guard: Guest,
    layout: LoginLayout,
    component: lazy(() => import('src/views/Login'))
  },
  {
    path: '/password',
    title: '忘记密码',
    guard: Guest,
    layout: LoginLayout,
    routes: [
      {
        exact: true,
        path: '/password/forget',
        title: '找回密码',
        component: lazy(() => import('src/views/Forget'))
      }
    ]
  },
  {
    path: '/register',
    title: '注册',
    guard: Guest,
    layout: LoginLayout,
    routes: [
      {
        exact: true,
        path: '/register/company',
        title: '企业机构注册',
        component: lazy(() => import('src/views/Register'))
      }
    ]
  },
  {
    exact: true,
    path: '/',
    title: '首页',
    component: () => <Redirect to="/login" />
  }
]

export default routes
