import React, { FC, ReactNode } from 'react'
import LoginHeader from './LoginHeader'
import Footer from './Footer'

interface BaseLayoutProps {
  children?: ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  return (
    <>
      <div className="w-full min-h-full relative" style={{paddingBottom: '80px'}}>
        <LoginHeader />
        <div>{children}</div>
        <Footer />
      </div>
    </>
  )
}

export default BaseLayout
